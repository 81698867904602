import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { faCircleExclamation, faUser, faUserEdit, faUserPlus, faUserGroup } from '@fortawesome/free-solid-svg-icons';

import { EnrollmentService } from 'src/app/core/services/enrollment.service';
import { StudentEnrollData, Student } from 'src/app/core/models/student.model';
import { LoginService } from 'src/app/core/services/login.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { ManagementUser } from '../../core/models/management-user.model';

@Component({
  selector: 'wf-enrollment',
  templateUrl: './enrollment.component.html'
})
export class EnrollmentComponent implements OnInit, AfterViewInit {
  userData: ManagementUser | null = null ;
  constructor(
    private enrollmentService: EnrollmentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private sessionStorageService: SessionStorageService,
  ) {
      // Set up form controls
      this.enrollmentForm = new FormGroup({
        englishLanguageLearner: new FormControl(false),
        extraSupportNeeded: new FormControl(false),
        firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        middleName: new FormControl(null, Validators.maxLength(50)),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        gender: new FormControl(''),
        grade: new FormControl(null, { updateOn: 'change', validators: Validators.required }),
        username: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20),
          Validators.pattern('^([a-zA-Z0-9_!?.-]){5,20}$')
        ]),
        password: new FormControl('', [
          Validators.required,
          Validators.maxLength(20)
        ]),
        sessionLength: new FormControl('', Validators.required),
        timerEnabled: new FormControl(true),
        canBeAssignedToTeam: new FormControl(true),
        curriculum: new FormControl(''),
        schoolClass: new FormControl(null),
        enrolledSubscription: new FormControl(0, Validators.required),
        betaTasksEnabled: new FormControl(false),
        betaSpeechTasksEnabled: new FormControl(false)
      },
      {
        updateOn: 'blur'
      });
  }

  enrollmentForm: FormGroup;
  // viewchild for focus on input element
  @ViewChild('firstnameEl') firstNameElement: ElementRef | undefined;

  // Other vars
  errorMessage: string = "";
  invalidUsername: boolean = false;
  invalidPassword: boolean = false;
  role: string | undefined = '';
  isParent: boolean = false;
  showCheckboxes: boolean = false;
  showSubscriptionType: boolean = false;
  showDiagnosticLicenses: boolean = true;
  // NOTE: keep of track of the old grade when grade changes for check to see if we need to update curricula
  oldGrade: number = -1;
  timeValues: number[] = [15, 20, 25, 30, 45, 60];
  showTimerOption: boolean = false;
  showInterventionLicenses: boolean = false;
  // List of characters we don't allow in passwords
  // TODO: need to revist these and make sure I can get rid of some of these requirements
  notAllowedPasswordChars = ['"', "\'", '\\', '/', ';'];
  notAllowedUsernameChars = ['=', '<', '>', '+', '"', '\'', '\\', '/', ','];

  errorIcon = faCircleExclamation ;

  submitted: boolean = false;
  password: any = {
    title: "Must be between 5 and 20 alphanumeric characters and include at least one number",
    regex: "",
    helpBlock: ""
  }
  numberOfInterventionLicenses: number = 0;
  numberOfDiagnosticLicenses: number = 0;
  disableInterventionLicenses: boolean = true;
  disableDiagnosticLicenses: boolean = true;
  showCurriculum: boolean = false;
  // Enrollment options from resolver
  enrollmentOptions: any;
  // Student data to edit from resolver
  editStudentData: any;
  isEditingStudent: boolean = false;

  // Add/Edit form dependent variables
  formTitle: string = '';
  passwordInputType: string = '';

  // Icons
  studentIcon = faUserPlus ;
  enrollIcon = faUserGroup ;

  ngOnInit(): void {
    let resolveData: any = this.activatedRoute.snapshot.data;
    this.enrollmentOptions = resolveData.enrollData;
    this.editStudentData = resolveData?.studentData || null;
    this.isEditingStudent = (this.router.url.includes('student/edit') && this.editStudentData !== null);
    this.userData = this.sessionStorageService.getUserData() ;

    if (this.isEditingStudent)
    {
      this.formTitle = 'Edit Student' ;
      this.studentIcon = faUserEdit ;
    }
    else
    {
      this.formTitle = 'Add Student' ;
      this.studentIcon = faUserPlus ;
    }

    this.passwordInputType = this.isEditingStudent ? 'password' : 'text';
    if (this.editStudentData && this.editStudentData.externalIdentities && Object.keys(this.editStudentData.externalIdentities).length > 0) {
      this.enrollmentForm.get('password')?.disable();
    }
    this.numberOfDiagnosticLicenses = this.enrollmentOptions.remainingDiagnosticLicenesesCount;
    this.numberOfInterventionLicenses = this.enrollmentOptions.remainingInterventionLicensesCount;
    this.disableInterventionLicenses = this.numberOfInterventionLicenses === 0;
    this.disableDiagnosticLicenses = this.numberOfDiagnosticLicenses === 0;

    if (this.isEditingStudent) {
      this.updateCurriculumOptions(this.editStudentData.grade);
    } else {
      this.updateCurriculumOptions(7); // Grab secondary curriculum by default
    }

    // FILSS2-44 Temporary disable some password requirements
    let useLessRestrictivePasswordRules = (this.sessionStorageService.getUserData()?.schoolID === 1);
    this.setPasswordValidators(useLessRestrictivePasswordRules);

    this.role = this.sessionStorageService.getUserData()?.userRole;
    this.isParent = this.sessionStorageService.getUserData()!.isParentUser() ;
    this.showCheckboxes = !this.isParent;
    this.showSubscriptionType = !this.isParent && !this.isEditingStudent;

    // Only show curriculum if appropriate role and not editing a student
    this.showCurriculum = (this.role === 'ROLE_TEACHER_CURRICULUM') && !this.isEditingStudent;

    // Get the number of diagnostic and intervention licenses
    this.showDiagnosticLicenses = this.numberOfDiagnosticLicenses !== -1;
    this.showInterventionLicenses = this.numberOfInterventionLicenses !== -1;

    if (this.isParent && (this.numberOfInterventionLicenses === 0)) {
      // TODO this could probably be done with a guard?
      // Protect against URL being manually entered with no licenses
      this.router.navigateByUrl('/students');
    }

    if (this.isEditingStudent) {
      this.populateEditForm();
    } else {
      this.setDefaultValues();
      // Initialize enrollment options
      this.initializeSubscription();
      this.enrollmentForm.get('curriculum')?.setValue(this.enrollmentOptions.curriculumOptions[0]);
    }
    // Set timer option after setting form options
    this.setShowTimerOption();
  }

  ngAfterViewInit() {
    // focus on first name after init and set default values
    if (!this.isEditingStudent) {
      this.firstNameElement?.nativeElement.focus();
    }
  }

  updateCurriculumOptions(newGrade: number) {
    this.enrollmentService.getCurriculumOptions(newGrade)
      .subscribe((res: any) => {
        this.enrollmentOptions.curriculumOptions = res;
      });
    }

  initializeUsernameErrorMessage(): void {
    this.errorMessage = "The username is required and must be between 5 and 20 alphanumeric characters in length."
    this.invalidUsername = false;
  }

  initializeSubscription() {
    if (this.enrollmentOptions.assessmentTypeOptions.length > 0) {
      let typeIndex = 0;
      if (this.isParent) {
        typeIndex = 2;
      }

      this.enrollmentForm.get('enrolledSubscription')?.setValue(this.enrollmentOptions.assessmentTypeOptions[typeIndex].SubscriptionTypeId.toString());
    }
  }

  gradeChanged() {
    // Check to see if we need to update the curriculum list based on switch of grade
    let newGrade = this.enrollmentForm.get('grade')?.value;
    if (!newGrade) {
      return;
    }

    let oldIsElementary = ((this.oldGrade !== -1) && (this.oldGrade <= 5));
    let newIsElementary = (newGrade <= 5);

    // After checking, set the old grade to the new grade
    this.oldGrade = newGrade;
    if (oldIsElementary !== newIsElementary) {
      this.updateCurriculumOptions(newGrade);
    }
  }

  updateNumberOfSubscriptions()  {
    this.enrollmentService.getRemainingDiagnosticLicensesCount(null, null)
      .subscribe((res: number) => {this.numberOfDiagnosticLicenses = res;});

    this.enrollmentService.getRemainingInterventionLicensesCount(null, null)
      .subscribe((res: number) => {this.numberOfInterventionLicenses = res;});
  }

  confirmedEnrollment() {
    // Build the new student object from enrollment form
    let studentData: StudentEnrollData = this.buildStudentEnrollObject();
    this.enrollmentService.sendEnrollmentInfo(studentData).subscribe((data: any) => {
      // Handle any errors and exit early if needed
      if (data && data.error) {
        this.handleEnrollmentError(data.error);
        return;
      }
      // reset the form and say not submitted to try submitting again
      this.enrollmentForm.reset();
      this.setDefaultValues();
      this.initializeSubscription();
      this.firstNameElement?.nativeElement.focus();
      this.submitted = false;

      // If we had one license left for the parent, we just used it,
      // so we need to transition to student list next.  Otherwise, stay
      // on the page
      let goToStudentList = (this.isParent && (this.numberOfInterventionLicenses == 1));

      this.updateNumberOfSubscriptions();
      let enrolledSubscription = this.enrollmentForm.get('enrolledSubscription')?.value;
      if (enrolledSubscription && enrolledSubscription === 2) {
        // Access code assigned to a student - make sure that
        // we update our signed in user's permissions

        let userLoggedIn = this.sessionStorageService.getUserData();
        if (userLoggedIn != null) {
          userLoggedIn.fullProductSubscriptionAssigned = true;
          this.sessionStorageService.setUserData(userLoggedIn);
        }
      }

      if (goToStudentList) {
        this.router.navigateByUrl('/students');
      }
    });
  }

  saveEditStudent() {
    let studentEnrollData: StudentEnrollData = this.buildStudentEnrollObject();
    let studentData: Student = { ...this.editStudentData, ...studentEnrollData };
    this.enrollmentService.updateStudentInfo(studentData).subscribe((data: any) => {
      // Handle any errors and exit early if needed
      if (data && data.error) {
        this.handleEnrollmentError(data.error);
        return;
      }
      this.router.navigateByUrl('/students');
    });
  }

  saveStudent() {
    // Variable to let me know if password and/or username is invalid or not
    this.invalidPassword = false;
    this.invalidUsername = false;

    // NOTE checks to make sure username doesn't have two '.' next to each other
    // OPTIMIZE: because '.' isn't allowed in username pattern, could probably delete this
    let username: string = this.enrollmentForm.get('username')?.value;
    for (let i = 0; i < username.length; i++) {
      if (username.charAt(i) === '.' && username.charAt(i + 1) === '.') {
        this.invalidUsername = true;
        this.enrollmentForm.get('username')?.setValue('');
      }
    }

    if (this.invalidUsername || this.invalidPassword) {
      console.log("Password or username was invalid");
    } else {
      if (this.isEditingStudent) {
        this.saveEditStudent();
      } else {
        this.confirmedEnrollment();
      }
    }
  }

  handleEnrollmentError(error: any) {
    if (error.message.includes('Duplicate')) {
      this.invalidUsername = true;
      this.errorMessage = "Username already exists!";
    }
    else {
      this.invalidUsername = true;
      this.errorMessage = "The username and password must match the requirements!";
    }
  }

  setDefaultValues() {
    this.enrollmentForm.patchValue({
      sessionLength: this.timeValues[1],
      canBeAssignedToTeam: true,
      timerEnabled: true,
      extraSupportNeeded: false,
      englishLanguageLearner: false,
      grade: null
    });
  }

  setPasswordValidators(useLessRestrictivePasswordRules: boolean) {
    let minLength = 8;
    if (useLessRestrictivePasswordRules) {
      this.password.regex = "^(?=.*[0-9])([a-zA-Z0-9_!?]){5,20}$";
      this.password.helpBlock = "The password is required and must be between 5 and 20 alphanumeric characters in length and include at least one number.";
      this.password.title = "Must be between 5 and 20 alphanumeric characters and include at least one number";
      minLength = 5;
    } else {
      this.password.regex = '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9_!?]){8,20}$';
      this.password.helpBlock = "The password is required and must be between 8 and 20 alphanumeric characters in length and include at least one letter and one number.";
      this.password.title = "Must be between 8 and 20 alphanumeric characters using letters and at least one number";
    }
    this.enrollmentForm.controls['password'].addValidators([Validators.minLength(minLength), Validators.pattern(this.password.regex)]);
  }

  populateEditForm() {
    this.enrollmentForm.patchValue({
      englishLanguageLearner: this.editStudentData.englishLanguageLearner,
      extraSupportNeeded: this.editStudentData.extraSupportNeeded,
      firstName: this.editStudentData.firstName,
      middleName: this.editStudentData.middleName,
      lastName: this.editStudentData.lastName,
      gender: this.editStudentData.gender.toString(),
      grade: this.editStudentData.grade,
      username: this.editStudentData.username,
      password: this.editStudentData.password,
      sessionLength: this.editStudentData.sessionLength,
      timerEnabled: this.editStudentData.timerEnabled,
      canBeAssignedToTeam: this.editStudentData.canBeAssignedToTeam,
      curriculum: this.editStudentData.curriculum,
      schoolClass: this.editStudentData.schoolClass?.name,
      enrolledSubscription: this.editStudentData.enrolledSubscription,
      betaTasksEnabled: this.editStudentData.betaTasksEnabled,
      betaSpeechTasksEnabled: this.editStudentData.betaSpeechTasksEnabled,
    });
  }

  buildStudentEnrollObject(): StudentEnrollData {
    let teacherID: number | undefined = this.sessionStorageService.getUserData()?.userID;

    return {
      firstName: this.enrollmentForm.get('firstName')?.value,
      middleName: this.enrollmentForm.get('middleName')?.value || null,
      lastName: this.enrollmentForm.get('lastName')?.value,
      username: this.enrollmentForm.get('username')?.value,
      password: this.enrollmentForm.get('password')?.value,
      gender: this.enrollmentForm.get('gender')?.value,
      grade: this.enrollmentForm.get('grade')?.value,
      englishLanguageLearner: this.enrollmentForm.get('englishLanguageLearner')?.value,
      extraSupportNeeded: this.enrollmentForm.get('extraSupportNeeded')?.value,
      timerEnabled: this.enrollmentForm.get('timerEnabled')?.value,
      enabled: true,
      curriculum: this.enrollmentForm.get('curriculum')?.value,
      sessionLength: this.enrollmentForm.get('sessionLength')?.value,
      teacherID: teacherID,
      betaTasksEnabled: this.enrollmentForm.get('betaTasksEnabled')?.value,
      betaSpeechTasksEnabled: this.enrollmentForm.get('betaSpeechTasksEnabled')?.value,
      userRole: 'ROLE_STUDENT',
      enrolledSubscription: parseInt(this.enrollmentForm.get('enrolledSubscription')?.value, 10),
      canBeAssignedToTeam: this.enrollmentForm.get('canBeAssignedToTeam')?.value,
      // TODO: eventually should maybe set this as an actual SchoolClass object?
      schoolClass: {name: this.enrollmentForm.get('schoolClass')?.value},
    };
  }

  setShowTimerOption() {
    if (this.isEditingStudent) {
      this.enrollmentOptions.assessmentTypeOptions.forEach((assessment: any) => {
        if (assessment.AssessmentType.toUpperCase() === 'INTERVENTION') {
          this.showTimerOption = this.enrollmentForm.get('enrolledSubscription')?.value === assessment.SubscriptionTypeId;
        }
      });
    } else {
      this.showTimerOption = this.numberOfInterventionLicenses !== 0;
    }
  }

  get firstName() {
    return this.enrollmentForm.get('firstName') ;
  }

  get lastName() {
    return this.enrollmentForm.get('lastName') ;
  }

  getUsername() {
    return this.enrollmentForm.get('username') ;
  }

  getPassword() {
    return this.enrollmentForm.get('password') ;
  }
}
